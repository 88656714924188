import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Card, Button, Typography, message } from 'antd';

const { Title } = Typography;

const Privacypolicy = () => {
    const [editorData, setEditorData] = useState('<h2>Privacy Policy</h2>'); // Default content
    const hosturl = 'https://api.awgfinserv.com';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${hosturl}/api/privacypolicy`);
                if (!response.ok) throw new Error('Failed to fetch terms');
                const result = await response.json();
                setEditorData(result.content || '<h2>Terms and Conditions</h2>');
            } catch (error) {
                message.error('Error fetching terms: ' + error.message);
            }
        };

        fetchData();
    }, []);

    const handleSave = async () => {
        try {
            const response = await fetch(`${hosturl}/api/privacypolicy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content: editorData }),
            });
            if (!response.ok) throw new Error('Failed to save terms');

            message.success('Terms and Conditions saved successfully!');
        } catch (error) {
            message.error('Error saving terms: ' + error.message);
        }
    };

    return (
        <Card style={{ maxWidth: 800, margin: '20px auto', borderRadius: '8px', overflowY:'auto', maxHeight:'100vh' }}>
            <Title level={4}>Privacy Policy</Title>
            <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data);
                }}
            />
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <Button type="primary" onClick={handleSave}>Save</Button>
            </div>
            <Title level={4}>Preview</Title>
            <div 
                style={{ border: '1px solid #d9d9d9', padding: '10px', borderRadius: '4px', background: '#fafafa', marginBottom: '100px' }}
                dangerouslySetInnerHTML={{ __html: editorData }} 
            />
        </Card>
    );
};

export default Privacypolicy;
