import React, { useState } from 'react';
import { Form, Input, Button, Upload, message, Row, Col, Card } from 'antd';
import { UploadOutlined, UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Item } = Form;

const Register = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false); // Track if phone number is already registered
  const [phoneNumberError, setPhoneNumberError] = useState(''); // Error message for phone number
  const [phoneNumber, setPhoneNumber] = useState(''); // Track phone number input
  const navigate = useNavigate(); // Use navigate hook for redirection
  const hosturl = 'https://api.awgfinserv.com';

  // Handle file change
  const handleFileChange = ({ fileList }) => setFileList(fileList);

  // Handle phone number change and check if it exists
  const handlePhoneNumberChange = async (e) => {
    const enteredPhoneNumber = e.target.value;
    setPhoneNumber(enteredPhoneNumber);

    if (enteredPhoneNumber.length === 10) {
      try {
        const response = await axios.get(`${hosturl}/check-phone/${enteredPhoneNumber}`);
        if (response.status === 200) {
          setPhoneNumberExists(false); // Phone number is available
          setPhoneNumberError(''); // Clear any error message
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPhoneNumberExists(false); // Phone number is available
          setPhoneNumberError(''); // Clear any error message
        } else {
          setPhoneNumberExists(true); // Phone number already exists
          setPhoneNumberError('This phone number is already registered. Please use a different one.');
        }
      }
    } else {
      setPhoneNumberExists(false); // Reset if phone number is invalid
      setPhoneNumberError('');
    }
  };

  const onFinish = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('phoneNumber', values.phoneNumber);
    formData.append('username', values.username);
    formData.append('password', values.password);
    formData.append('isAppUser', values.isAppUser || true);

    // If file is uploaded, append it to formData
    if (fileList.length > 0) {
      formData.append('profileImage', fileList[0].originFileObj);
    }

    try {
      // Step 1: Register the user
      const registrationResponse = await axios.post(`${hosturl}/register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('Registration successful!');

      // Step 2: Auto login with phone number and password after successful registration
      const loginData = {
        phoneNumber: values.phoneNumber,
        password: values.password,
      };

      // Send login request
      const loginResponse = await axios.post(`${hosturl}/login`, loginData);

      // Log the login response to inspect it
      console.log('Login Response:', loginResponse.data);

      // Step 3: Store token or session info in localStorage or cookies (optional)
      if (loginResponse.data.token) {
        localStorage.setItem('authToken', loginResponse.data.token);
        localStorage.setItem('userInfo', JSON.stringify(loginResponse.data.user));
      }

      // Step 4: Redirect to the login page with the token (if needed)
      window.open(`https://userdashboard.awgfinserv.com/login?token=${loginResponse.data.token}`, '_blank');

    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error || error.response.data.message;
        if (errorMessage === 'This mobile number is already registered') {
          message.error('This phone number is already registered. Please use a different one.');
        } else {
          message.error('Registration or Login failed. Please try again!');
        }
      } else {
        message.error('Network error. Please try again!');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Render the profile image or placeholder if not uploaded
  const renderProfileImage = () => {
    if (fileList.length > 0) {
      return (
        <img
          src={URL.createObjectURL(fileList[0].originFileObj)} // Preview the uploaded image
          alt="profile"
          style={{ width: 100, height: 100, borderRadius: '50%' }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: 100,
            height: 100,
            borderRadius: '50%',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '40px',
            color: '#888',
          }}
        >
          <UserOutlined />
        </div>
      );
    }
  };

  return (
    <div style={{ maxHeight: '100vh', overflow: 'scroll', backgroundColor: '#f5f5f5' }}>
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate(-1)} // Navigate to the previous page
        style={{
        //   marginBottom: '20px',
          backgroundColor: '#2E8B57',
          borderColor: '#2E8B57',
          color: 'white',
          borderRadius: '25px',
          padding: '0px 20px',
          marginTop:'20px',
          marginLeft:'20px'
        }}
      >
        Go Back
      </Button>
      <Card
        style={{
          maxWidth: 750,
          margin: '50px auto',
          padding: '30px',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
        title={<h3 style={{ color: '#333' }}>Create Account</h3>}
      >
        <Form form={form} layout="vertical" onFinish={onFinish} style={{ maxWidth: '100%' }}>
          {/* Username */}
          <Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input
              placeholder="Enter your username"
              style={{
                borderRadius: '25px',
                borderColor: '#d9d9d9',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                marginBottom: '15px',
                padding: '10px 15px',
              }}
            />
          </Item>

          {/* Phone Number */}
          <Item
            label="Phone Number"
            name="phoneNumber"
            validateStatus={phoneNumberExists ? 'error' : ''}
            help={phoneNumberError || ''}
            rules={[
              { required: true, message: 'Please input your phone number!' },
              { pattern: /^[0-9]{10}$/, message: 'Phone number must be 10 digits long' },
            ]}
          >
            <Input
              maxLength={10}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Enter your phone number"
              style={{
                borderRadius: '25px',
                borderColor: phoneNumberExists ? 'red' : '#d9d9d9',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                padding: '10px 15px',
              }}
            />
          </Item>

          {/* Password */}
          <Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]} hasFeedback>
            <Input.Password
              placeholder="Enter your password"
              style={{
                borderRadius: '25px',
                borderColor: '#d9d9d9',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                marginBottom: '15px',
                padding: '10px 15px',
              }}
            />
          </Item>

          {/* Confirm Password */}
          <Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords do not match!');
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Confirm your password"
              style={{
                borderRadius: '25px',
                borderColor: '#d9d9d9',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                marginBottom: '15px',
                padding: '10px 15px',
              }}
            />
          </Item>

          {/* Profile Image (Optional) */}
          <Item label="Profile Image" name="profileImage">
            <Upload
              listType="picture"
              fileList={fileList}
              onChange={handleFileChange}
              beforeUpload={() => false} // Prevent automatic upload
            >
              <Button
                icon={<UploadOutlined />}
                style={{
                  width: '100%',
                  borderRadius: '25px',
                  backgroundColor: 'green',
                  color: 'white',
                  borderColor: '#2E8B57',
                  padding: '1px',
                }}
              >
                upload profile picture 
              </Button>
            </Upload>
          </Item>

          {/* Display Profile Image or User Circle */}
          <Item label="Profile Preview">
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
              {renderProfileImage()}
            </div>
          </Item>

          {/* Submit Button */}
          <Row justify="center">
            <Col span={24}>
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                    padding: '1px 20px',
                    borderRadius: '25px',
                    backgroundColor: 'green',
                    color: 'white',
                    borderColor: '#2E8B57',
                  }}
                >
                  Register
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default Register;
