import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spin, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import {
  UserOutlined,
  ShoppingCartOutlined,
  WalletOutlined,
  BulbOutlined,
  FileTextOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

const { Meta } = Card;
const { Text } = Typography;

const Dashboard = () => {
  const [dataCounts, setDataCounts] = useState({
    totalUsers: 0,
    totalPurchases: 0,
    totalPlans: 0,
    totalBankAccounts: 0,
    totalFeedbacks: 0,
    totalQAs: 0,
  });

  const hosturl = 'https://api.awgfinserv.com';
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataCounts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${hosturl}/api/dashboard/counts`);
        const counts = await response.json();

        setDataCounts({
          totalUsers: counts.totalUsers || 0,
          totalPurchases: counts.totalPurchases || 0,
          totalPlans: counts.totalPlans || 0,
          totalBankAccounts: counts.totalBankAccounts || 0,
          totalFeedbacks: counts.totalFeedbacks || 0,
          totalQAs: counts.totalQAs || 0,
        });
      } catch (error) {
        console.error('Error fetching data counts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataCounts();
  }, []);

  if (loading) {
    return (
      <div style={styles.loading}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={styles.dashboard}>
      <h2 style={styles.headerTitle}>Dashboard</h2>
      <Row gutter={[16, 24]}>
        {renderCard('Total Users', dataCounts.totalUsers, 'Users in the system', <UserOutlined />, '/home/Userlist')}
        {renderCard('Total Purchases', dataCounts.totalPurchases, 'Total purchases made', <ShoppingCartOutlined />, '/home/investments')}
        {renderCard('Total Plans', dataCounts.totalPlans, 'Available plans', <FileTextOutlined />, '/home/plans/current')}
        {renderCard('Total Bank Accounts', dataCounts.totalBankAccounts, 'Registered bank accounts', <WalletOutlined />, '/home/AddBank')}
        {renderCard('Total Feedbacks', dataCounts.totalFeedbacks, 'User feedbacks received', <BulbOutlined />, '/home/feedback')}
        {renderCard('Total Q&A', dataCounts.totalQAs, 'Questions and Answers', <QuestionCircleOutlined />, '/home/frequentlyQuestions')}
      </Row>
     
    </div>
  );
};

const renderCard = (title, value, text, icon, link) => (
  <Col span={8}>
    <Card
      style={styles.card}
      hoverable
      cover={<div style={styles.iconContainer}>{icon}</div>}
    >
      <Meta
        title={title}
        description={
          <>
            <h2 style={styles.cardValue}>{value}</h2>
            <p style={styles.cardText}>{text}</p>
            <Link to={link}>
              <Button type="primary" style={styles.button}>Click here</Button>
            </Link>
          </>
        }
      />
    </Card>
  </Col>
);

export default Dashboard;

const styles = {
  dashboard: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f2f5',
    borderRadius: '10px',
    maxHeight: '103%', // Set maximum height for the dashboard
    overflowY: 'auto',
    width:'102%',
    // marginTop:20,
    marginBottom:20
  },
  headerTitle: {
    // marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
  },
  card: {
    borderRadius: '8px', // Make the cards smaller
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    // padding: '10px', // Add padding to cards
  },
  iconContainer: {
    fontSize: '30px', // Smaller icon size
    color: '#1890ff',
    // padding: '10px 0',
  },
  cardValue: {
    fontSize: '24px', // Smaller font size for card values
    margin: '10px 0',
    color: '#333',
  },
  cardText: {
    fontSize: '12px',
    color: '#888',
  },
  button: {
    // marginTop: '10px',
    borderRadius: '5px',
  },
  loading: {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '20px',
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: '30px',
  },
  link: {
    fontSize: '16px',
    color: '#1890ff',
    textDecoration: 'none',
    fontWeight: 'bold',
    transition: 'color 0.3s',
  },
};

// Add hover effect for link
styles.linkContainer[':hover'] = {
  color: '#40a9ff',
};
