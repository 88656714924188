import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload, message, List, Popconfirm } from 'antd';
import axios from 'axios';

const Referralscreen = () => {
    const [form] = Form.useForm();
    const [image, setImage] = useState(null);
    const [referrals, setReferrals] = useState([]);
    const [editingReferral, setEditingReferral] = useState(null);
    const hosturl = 'https://api.awgfinserv.com';

    // Fetch referrals on component mount
    useEffect(() => {
        fetchReferrals();
    }, []);

    const fetchReferrals = async () => {
        try {
            const response = await axios.get(`${hosturl}/api/referrals`);
            setReferrals(response.data);
        } catch (error) {
            message.error('Failed to fetch referrals');
        }
    };

    const onFinish = async (values) => {
        const { link, mainText, customText } = values;
        
        const formData = new FormData();
        if (image) {
          formData.append('referralImage', image); // Ensure this is included when updating
        }
        formData.append('link', link);
        formData.append('mainText', mainText);
        formData.append('customText', customText);
      
        try {
          if (editingReferral) {
            console.log("Updating referral with ID:", editingReferral._id);
            await axios.put(`${hosturl}/api/referrals/${editingReferral._id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            message.success('Referral updated successfully!');
          } else {
            await axios.post(`${hosturl}/api/referrals`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            message.success('Referral created successfully!');
          }
          form.resetFields();
          setImage(null);
          setEditingReferral(null);
          fetchReferrals();
        } catch (error) {
          message.error('Failed to submit data');
          console.error("Error during submission:", error);
        }
      };
      
    
    const handleImageChange = (info) => {
        if (info.file.status === 'done') {
            setImage(info.file.originFileObj); // Set the file object for upload
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const editReferral = (referral) => {
        form.setFieldsValue({
            link: referral.link,
            mainText: referral.mainText,
            customText: referral.customText,
        });
        setImage(referral.image); // Set image to display for editing
        setEditingReferral(referral);
    };

    const deleteReferral = async (id) => {
        try {
            await axios.delete(`${hosturl}/api/referrals/${id}`);
            message.success('Referral deleted successfully!');
            fetchReferrals();
        } catch (error) {
            message.error('Failed to delete referral');
        }
    };

    return (
        <div style={{ padding: 20, maxHeight:'100%', overflowY:'scroll' }}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label="Image">
                    <Upload
                        name="referralImage"
                        action="https://api.awgfinserv.com/api/referrals/upload"
                        onChange={handleImageChange}
                        showUploadList={false}
                    >
                        <Button>Upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    name="link"
                    label="Link"
                    rules={[{ required: true, message: 'Please input the link!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="mainText"
                    label="Main Text"
                    rules={[{ required: true, message: 'Please input the main text!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="customText"
                    label="Custom Text"
                >
                    <Input />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    {editingReferral ? 'Update Referral' : 'Submit'}
                </Button>
            </Form>

            <List
                header={<div>Referrals</div>}
                bordered
                dataSource={referrals}
                renderItem={referral => (
                    <List.Item
                        actions={[
                            <Button onClick={() => editReferral(referral)}>Edit</Button>,
                            <Popconfirm
                                title="Are you sure to delete this referral?"
                                onConfirm={() => deleteReferral(referral._id)}
                            >
                                <Button type="danger">Delete</Button>
                            </Popconfirm>
                        ]}
                    >
                        <List.Item.Meta
                            title={referral.mainText}
                            description={
                                <>
                                    <p>Link: {referral.link}</p>
                                    <p>Custom Text: {referral.customText}</p>
                                    <img src={referral.image} alt={referral.mainText} style={{ width: '100px', height: 'auto' }} />
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>
    );

};
export default Referralscreen;
