import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, message, List, Image, Row, Col, Card } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import Compressor from 'compressorjs';  // For Image Compression
import GIF from 'gif.js.optimized';  // For GIF Compression

const PostManager = () => {
  const [posts, setPosts] = useState([]);
  const [editingPost, setEditingPost] = useState(null);
  const [text, setText] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const hosturl = 'https://api.awgfinserv.com'; // Change this to your actual backend URL

  // Fetch posts from the backend
  const fetchPosts = async () => {
    try {
      const response = await axios.get(`${hosturl}/floatmedia`);
      setPosts(response.data);
    } catch (error) {
      message.error('Error fetching posts');
    }
  };

  useEffect(() => {
    fetchPosts(); // Fetch posts when the component mounts
  }, []);

  // Function to handle image compression
  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6, // Set the compression quality (0 to 1)
        maxWidth: 800, // Set the max width for the compressed image
        maxHeight: 800, // Set the max height for the compressed image
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        }
      });
    });
  };

  // Function to handle GIF compression
  const compressGIF = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const gif = new GIF({
          workers: 2,
          quality: 10,  // Compression level for the GIF
          width: 300,   // Resize GIF width
          height: 300,  // Resize GIF height
        });
        
        gif.addFrame(event.target.result, {delay: 100}); // Add frame for compression
        gif.on('finished', function(blob) {
          resolve(blob); // Resolve the compressed GIF
        });
        
        gif.render();
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  // Handle image or gif selection
  const handleImageChange = ({ fileList }) => {
    setFileList(fileList);
    const file = fileList[0];
    if (file) {
      const fileType = file.type.split('/')[0]; // Check if it's an image or gif

      if (fileType === 'image') {
        // Compress image
        compressImage(file.originFileObj)
          .then((compressedImage) => {
            setImageUrl(URL.createObjectURL(compressedImage));
          })
          .catch((error) => {
            message.error('Error compressing the image');
          });
      } else if (fileType === 'image' && file.type === 'image/gif') {
        // Compress GIF
        compressGIF(file.originFileObj)
          .then((compressedGIF) => {
            setImageUrl(URL.createObjectURL(compressedGIF));
          })
          .catch((error) => {
            message.error('Error compressing the GIF');
          });
      }
    }
  };

  const beforeUpload = (file) => {
    const isImageOrGif = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isImageOrGif) {
      message.error('You can only upload JPG, PNG, or GIF files!');
    }
    return isImageOrGif;
  };

  const handleAddPost = async () => {
    try {
      const formData = new FormData();
      formData.append('text', text);
      if (fileList.length > 0) {
        formData.append('media', fileList[0].originFileObj);
      }

      const response = await axios.post(`${hosturl}/floatupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('Post added successfully!');
      setText('');
      setImageUrl(null);
      setFileList([]);
      fetchPosts(); // Refresh the post list after adding
    } catch (error) {
      message.error('Error adding post');
    }
  };

  const handleEditPost = (post) => {
    setEditingPost(post);
    setText(post.text);
    setImageUrl(post.mediaUrl);
    setFileList([]); // Clear the fileList when editing, since we are editing an existing post
  };

  const handleSaveEdit = async () => {
    try {
      const formData = new FormData();
      formData.append('text', text);
      if (fileList.length > 0) {
        formData.append('media', fileList[0].originFileObj);
      }

      const response = await axios.put(`${hosturl}/floatmedia/${editingPost._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('Post updated successfully!');
      setEditingPost(null);
      setText('');
      setImageUrl(null);
      setFileList([]);
      fetchPosts(); // Refresh the post list after editing
    } catch (error) {
      message.error('Error updating post');
    }
  };

  const handleDeletePost = async (id) => {
    try {
      await axios.delete(`${hosturl}/floatmedia/${id}`);
      message.success('Post deleted successfully!');
      fetchPosts(); // Refresh the post list after deleting
    } catch (error) {
      message.error('Error deleting post');
    }
  };

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '20px', overflow: 'auto', maxHeight: '100vh' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '30px', fontSize: '28px', fontWeight: '600', color: '#333' }}>
        Post Manager
      </h2>

      {/* Post Form */}
      <Form layout="vertical" style={{ boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '8px', background: '#fff' }}>
        <Form.Item label="Text" required>
          <Input.TextArea
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter text for your post"
            rows={4}
            style={{ borderRadius: '8px', padding: '10px' }}
          />
        </Form.Item>

        <Form.Item label="Upload Image or GIF">
          <Upload
            name="file"
            listType="picture-card"
            fileList={fileList}
            onChange={handleImageChange}
            beforeUpload={beforeUpload}
            showUploadList={false}
            accept="image/*"
          >
            <Button icon={<UploadOutlined />} style={{ background: '#007bff', color: '#fff', borderRadius: '8px' }}>
              Select Image or GIF
            </Button>
          </Upload>
          {imageUrl && <Image src={imageUrl} alt="Uploaded Image" width={100} style={{ marginTop: 10, borderRadius: '8px' }} />}
        </Form.Item>

        <Form.Item>
          {!editingPost ? (
            <Button
              type="primary"
              onClick={handleAddPost}
              disabled={!text}
              block
              style={{
                backgroundColor: '#28a745',
                borderColor: '#28a745',
                fontSize: '16px',
                borderRadius: '8px',
              }}
            >
              Add Post
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={handleSaveEdit}
              disabled={!text}
              block
              style={{
                backgroundColor: '#ffc107',
                borderColor: '#ffc107',
                fontSize: '16px',
                borderRadius: '8px',
              }}
            >
              Save Changes
            </Button>
          )}
        </Form.Item>
      </Form>

      <h3 style={{ textAlign: 'center', marginBottom: '30px', fontSize: '24px', fontWeight: '500', color: '#333' }}>Posts</h3>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={posts}
            renderItem={(post) => (
              <List.Item
                key={post._id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '15px',
                  borderRadius: '10px',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#f8f9fa',
                  marginBottom: '20px',
                }}
              >
                <Card
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    padding: '15px',
                    backgroundColor: '#fff',
                  }}
                  title={`Post #${post._id}`}
                >
                  <div style={{ flex: 1, marginBottom: '10px' }}>
                    <p style={{ fontSize: '16px', color: '#333' }}>{post.text}</p>
                    {post.mediaUrl && (
                      <Image
                        src={`${hosturl}${post.mediaUrl}`}
                        alt="Post Image"
                        width={150}
                        style={{ borderRadius: '8px', marginTop: '10px' }}
                      />
                    )}
                  </div>

                  {/* Action buttons below text and image */}
                  <div style={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      onClick={() => handleEditPost(post)}
                      type="link"
                      style={{ fontSize: '14px', color: '#007bff' }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDeletePost(post._id)}
                      type="link"
                      danger
                      style={{ fontSize: '14px' }}
                    >
                      Delete
                    </Button>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PostManager;
