import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form, Input, notification } from 'antd';
import { FaTachometerAlt } from 'react-icons/fa';

export default function CurrentPlansComponent() {
  const hosturl = 'https://api.awgfinserv.com';
  const [plans, setPlans] = useState([]);
  const [editingPlan, setEditingPlan] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${hosturl}/plans`);
        const plansData = response.data.map(plan => ({
          id: plan.planID,
          name: plan.name,
          minInvestment: plan.minAmount,
          roi: plan.interestRate,
          duration: plan.numberOfMonths,
          maxAmount: plan.maxAmount,
        }));
        setPlans(plansData);
      } catch (error) {
        console.error('Error retrieving plans:', error.message);
      }
    };

    fetchPlans();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${hosturl}/plans/${id}`);
      setPlans(plans.filter(plan => plan.id !== id));
    } catch (error) {
      console.error('Error deleting plan:', error.message);
    }
  };

  const showEditModal = (plan) => {
    setEditingPlan(plan);
    setIsModalVisible(true);
  };

  const handleEdit = async (values) => {
    try {
      await axios.put(`${hosturl}/plans/${editingPlan.id}`, values);
      setPlans(plans.map(plan => 
        plan.id === editingPlan.id 
          ? { ...plan, ...values } 
          : plan
      ));
      setIsModalVisible(false);
      setEditingPlan(null);
      notification.success({
        message: 'Success',
        description: 'Plan updated successfully!',
      });
    } catch (error) {
      console.error('Error updating plan:', error.message);
    }
  };

  const columns = [
    { title: 'S.No', render: (text, record, index) => index + 1 },
    { title: 'Plan ID', dataIndex: 'id' },
    { title: 'Plan Name', dataIndex: 'name' },
    { title: 'Min Investment (INR)', dataIndex: 'minInvestment' },
    { title: 'ROI', dataIndex: 'roi' },
    { title: 'Min Duration', dataIndex: 'duration', render: (text) => `${text} months` },
    {
      title: 'Actions',
      render: (text, plan) => (
        <>
          <Button type="primary" onClick={() => showEditModal(plan)}>Edit</Button>
          <Button danger onClick={() => handleDelete(plan.id)}>Delete</Button>
        </>
      ),
    },
  ];

  const styles = {
    container: {
      padding: '20px',
      background: '#f7f9fc',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      overflowY:'scroll',
      height:'100%',
      width:'102%'
    },
    headingContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    iconContainer: {
      fontSize: '24px',
      marginRight: '10px',
      color: '#1890ff',
    },
    heading: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    table: {
      background: 'white',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    modal: {
      borderRadius: '8px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.headingContainer}>
        <div style={styles.iconContainer}>
          <FaTachometerAlt className="icon" />
        </div>
        <h5 style={styles.heading}>Current Plans</h5>
      </div>

      <Table dataSource={plans} columns={columns} rowKey="id" pagination={false} />

      <Modal
        title="Edit Plan"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        bodyStyle={styles.modal}
      >
        <Form
          initialValues={{
            name: editingPlan ? editingPlan.name : '',
            minAmount: editingPlan ? editingPlan.minInvestment : '',
            interestRate: editingPlan ? editingPlan.roi : '',
            numberOfMonths: editingPlan ? editingPlan.duration : '',
            maxAmount: editingPlan ? editingPlan.maxAmount : '',
          }}
          onFinish={handleEdit}
        >
          <Form.Item name="name" label="Plan Name" rules={[{ required: true, message: 'Please input the plan name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="minAmount" label="Min Investment (INR)" rules={[{ required: true, message: 'Please input the minimum investment!' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="interestRate" label="ROI" rules={[{ required: true, message: 'Please input the ROI!' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="numberOfMonths" label="Min Duration" rules={[{ required: true, message: 'Please input the duration!' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="maxAmount" label="Max Investment (INR)">
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
