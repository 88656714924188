import React, { useState, useEffect } from 'react';
import {
  CircularProgress, Button, Modal, TextField, Box, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './Plandetails.css';

const PlanDetails = () => {
  const [data, setData] = useState([]);  
  const [loading, setLoading] = useState(true);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [approvedAt, setApprovedAt] = useState(""); 
  const [expandedRow, setExpandedRow] = useState(null);
  const [editingReturn, setEditingReturn] = useState(null);
  const [returnStatus, setReturnStatus] = useState("");  // For tracking the new status
  const [errorMessage, setErrorMessage] = useState(null);
  const [isreturnsmodel, setIsReturnsModel] = useState(false);  // Control modal visibility

  useEffect(() => {
    fetch('https://api.awgfinserv.com/api/monthlyreturns')
      .then((response) => response.json())
      .then((result) => {
        setData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const handleEdit = async () => {
    try {
      // Ensure the date is valid
      const approvedAtDate = new Date(approvedAt);
      if (isNaN(approvedAtDate.getTime())) {
        setErrorMessage('Invalid date value');
        return;
      }
  
      const formattedApprovedAt = approvedAtDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      const { _id, purchasePlanId } = editingRecord;
  
      const response = await fetch('https://api.awgfinserv.com/api/updateApprovedAt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          _id,
          purchasePlanId,
          newApprovedAt: formattedApprovedAt,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to update approved date: ${errorData.error || response.statusText}`);
      }
  
      const result = await response.json();
      console.log(result.message);
  
      // Update local state immediately with new approvedAt and returns
      const updatedData = data.map((user) =>
        user._id === editingRecord._id
          ? {
              ...user,
              approvedAt: formattedApprovedAt,
              returns: user.returns.map((returnItem, index) => {
                const monthNumber = approvedAtDate.getMonth() + index + 1;
                const payDate = new Date(approvedAtDate);
                payDate.setMonth(approvedAtDate.getMonth() + index); // Set payDate based on month index
                return {
                  ...returnItem,
                  month: monthNumber,
                  payDate: payDate.toISOString(), // Update payDate
                  status: 'upcoming',  // Set status to upcoming
                };
              }),
            }
          : user
      );
  
      // Update state with new data immediately after a successful update
      setData(updatedData);
      setIsModalVisible(false);
      setErrorMessage(null);
  
    } catch (error) {
      setErrorMessage(`Error saving changes: ${error.message || 'Unknown error'}`);
    }
  };

  const handleReturnStatusChange = (status) => {
    setReturnStatus(status);  // Update the status in state
  };

  const handleSaveReturnStatus = async (e) => {
    e.preventDefault();

    // Ensure editingReturn is not null
    if (!editingReturn) {
      setErrorMessage("Return record is not available.");
      return;
    }

    if (!returnStatus) {
      setErrorMessage("Please select a valid status");
      return;
    }

    if (returnStatus !== 'upcoming' && returnStatus !== 'paid') {
      setErrorMessage("Invalid status. Please choose either 'Upcoming' or 'Paid'.");
      return;
    }

    if (returnStatus === 'paid') {
      if (!editingReturn.paidAt) {
        setErrorMessage("Please select a valid pay date.");
        return;
      }

      if (!editingReturn.transactionId || editingReturn.transactionId.trim() === '') {
        setErrorMessage("Please enter a valid transaction ID.");
        return;
      }
    }

    // Call the API to save the updated return status
    try {
      const response = await fetch('https://api.awgfinserv.com/api/updateReturnStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          returnId: editingReturn._id,
          status: returnStatus,
          paidAt: editingReturn.paidAt,
          transactionId: editingReturn.transactionId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to update return status: ${errorData.error || response.statusText}`);
      }

      const result = await response.json();
      console.log(result.message);

      // Update the data state with the updated return status
      // Assuming you have a function that updates the UI with the updated data
      updateReturnStatusInData(editingReturn._id, returnStatus, editingReturn.paidAt, editingReturn.transactionId);

      // Clear states after saving
      setEditingReturn(null);
      setReturnStatus("");
      setIsReturnsModel(false);  // Close the modal
      setErrorMessage(""); // Clear any error message

    } catch (error) {
      setErrorMessage(`Error saving return status: ${error.message}`);
    }
  };

  const updateReturnStatusInData = (returnId, status, paidAt, transactionId) => {
    const updatedData = data.map((user) => {
      if (user.returns) {
        const updatedReturns = user.returns.map((returnItem) => {
          if (returnItem._id === returnId) {
            return { ...returnItem, status, paidAt, transactionId };
          }
          return returnItem;
        });
        return { ...user, returns: updatedReturns };
      }
      return user;
    });

    setData(updatedData);
  };

  const toggleDetails = (record) => {
    setExpandedRow(expandedRow === record._id ? null : record._id);
  };

  const flattenedData = data.map((user) => ({
    ...user,
    returns: user.returns || [],
  }));

  // Helper function to format the date (show only the date portion)
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className="plan-details-container">
      {/* Table Header */}
      <h1 className="title">Plan Details</h1>

      <div className="table-container">
        <div className="table-row header">
          <div className="table-cell">S.No</div>
          <div className="table-cell">Username</div>
          <div className="table-cell">Phone Number</div>
          <div className="table-cell">Plan ID</div>
          <div className="table-cell">Status</div>
          <div className="table-cell">Approved At</div>
          <div className="table-cell">Actions</div>
        </div>

        {/* Data Rows */}
        {flattenedData.map((record, index) => (
          <div key={record._id}>
            <div className="table-row">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">{record.username}</div>
              <div className="table-cell">{record.phoneNumber}</div>
              <div className="table-cell">{record.planId}</div>
              <div className="table-cell">{record.status}</div>
              <div className="table-cell">{formatDate(record.approvedAt)}</div>
              <div className="table-cell">
                <div className="button-group">
                  <Button
                    onClick={() => {
                      setEditingRecord(record);
                      setApprovedAt(record.approvedAt || "");
                      setIsModalVisible(true);
                    }}
                    variant="outlined"
                    className="edit-button"
                  >
                    <EditIcon />
                  </Button>
                  <Button 
                    onClick={() => toggleDetails(record)} 
                    variant="outlined" 
                    className="more-details-button"
                  >
                    <VisibilityIcon />
                  </Button>
                </div>
              </div>
            </div>

            {/* Returns Details */}
            <div className={`returns-details ${expandedRow === record._id ? 'expanded' : ''}`}>
              <div className="returns-list">
                {record.returns && Array.isArray(record.returns) ? (
                  record.returns.map((returnItem) => (
                    <div key={returnItem._id} className="return-item">
                      <div className="return-cell">{returnItem.month}</div>
                      <div className="return-cell">{returnItem.amount}</div>
                      <div className="return-cell">{returnItem.status}</div>
                      <div className="return-cell">{new Date(returnItem.payDate).toLocaleDateString()}</div>
                      <div className="return-cell">
                        <Button
                          onClick={() => {
                            setEditingReturn(returnItem); 
                            setIsReturnsModel(true);
                          }}
                          variant="outlined"
                          className="edit-status-button"
                        >
                          <EditIcon />
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No returns available</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for editing return status */}
      <Modal
        open={isreturnsmodel}
        onClose={() => setIsReturnsModel(false)}
        aria-labelledby="edit-return-status-modal"
      >
        <Box className="edit-modal">
          <h4>Edit Return Status</h4>

          {errorMessage && <div className="error-message">{errorMessage}</div>}

          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={returnStatus}
              onChange={(e) => handleReturnStatusChange(e.target.value)}
              style={{ marginBottom: 10 }}
            >
              <MenuItem value="upcoming">Upcoming</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
            </Select>
          </FormControl>

          {returnStatus === 'paid' && (
            <TextField
              label="Pay At"
              type="date"
              fullWidth
              value={editingReturn?.paidAt || ""}
              onChange={(e) => setEditingReturn({ ...editingReturn, paidAt: e.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginBottom: 10 }}
            />
          )}

          {returnStatus === 'paid' && (
            <TextField
              label="Transaction ID"
              type="text"
              fullWidth
              value={editingReturn?.transactionId || ""}
              onChange={(e) => setEditingReturn({ ...editingReturn, transactionId: e.target.value })}
              style={{ marginBottom: 10 }}
            />
          )}

          <div className="modal-actions">
            <Button variant="contained" onClick={handleSaveReturnStatus}>Save</Button>
            <Button variant="outlined" onClick={() => setIsReturnsModel(false)}>Cancel</Button>
          </div>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="edit-record-modal"
      >
        <Box className="edit-modal">
          <h4 style={{ marginBottom: '20px' }}>Approved At</h4>
          <TextField
            label="Approved At"
            type="date"
            fullWidth
            value={approvedAt}
            onChange={(e) => setApprovedAt(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className="date-field"
          />

          <div className="modal-actions">
            <Button variant="contained" onClick={handleEdit}>Save</Button>
            <Button variant="outlined" onClick={() => setIsModalVisible(false)}>Cancel</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PlanDetails;
