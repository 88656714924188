import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Table, Button, Modal, message, Spin, Input } from 'antd';

const { Search } = Input;

const MonthlyReturns = () => {
    const [plans, setPlans] = useState([]);
    const [users, setUsers] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [transactionId, setTransactionId] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [view, setView] = useState('upcoming');
    const [searchTerm, setSearchTerm] = useState('');
    const hosturl = 'https://api.awgfinserv.com';
    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await fetch(`${hosturl}/api/monthlyreturns`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const data = await response.json();
                setPlans(data);

                const userPromises = data.map(plan =>
                    fetch(`${hosturl}/api/app-users/${plan.userId}`).then(res => res.json())
                );
                const usersData = await Promise.all(userPromises);
                const usersMap = usersData.reduce((acc, user) => {
                    acc[user._id] = user;
                    return acc;
                }, {});
                setUsers(usersMap);
            } catch (error) {
                console.error('Error fetching plans:', error);
                message.error('Failed to fetch plans');
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, []);

    const closeOverlay = () => {
        setSelectedPlan(null);
        setTransactionId('');
        setError(null);
    };

    const handlePayReturn = (plan) => {
        setSelectedPlan(plan); // Store the selected plan
        setTransactionId(''); // Clear any previous transaction ID
        setIsModalVisible(true); // Show the modal
    };
    

    const handleSubmitPayReturn = async () => {
        if (!transactionId) {
            setError('Transaction ID is required');
            return;
        }

        setSubmitting(true);
        const monthlyReturnId = selectedPlan._id;
        const month = selectedPlan.returns.find(r => r.status === 'upcoming').month;

        try {
            const response = await fetch(`${hosturl}/api/payReturn`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ monthlyReturnId, month, transactionId }),
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();
            message.success(data.message);
            closeOverlay();
        } catch (error) {
            console.error('Error paying return:', error);
            message.error('Failed to pay return');
        } finally {
            setSubmitting(false);
        }
    };

    const toggleView = (viewType) => {
        setView(viewType);
    };

    const calculateRemainingDays = (targetDate) => {
        const currentDate = new Date();
        const differenceInTime = targetDate - currentDate;
        return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    };

    const isPayable = (returnItem, approvedAt, paidAt) => {
        const now = new Date();
        const thirtyDays = 30 * 24 * 60 * 60 * 1000;

        if (!paidAt && approvedAt) {
            return (now - new Date(approvedAt)) >= thirtyDays;
        }

        if (paidAt) {
            return (now - new Date(paidAt)) >= thirtyDays;
        }

        return false;
    };

    const getSortedPlans = (plans) => {
        return plans
            .filter(plan => plan.status !== 'closed') // Filter out closed plans
            .map(plan => {
                const nextReturn = plan.returns.find(r => r.status === 'upcoming');
                const isPlanPayable = nextReturn && isPayable(nextReturn, plan.approvedAt, nextReturn.paidAt);
                return { ...plan, isPlanPayable };
            })
            .sort((a, b) => {
                return (b.isPlanPayable ? 1 : 0) - (a.isPlanPayable ? 1 : 0);
            });
    };

    const upcomingPlans = getSortedPlans(plans);

    const downloadData = () => {
        let dataToDownload = [];

        if (view === 'upcoming') {
            dataToDownload = plans.filter(plan => plan.status !== 'closed').map(plan => {
                const nextReturn = plan.returns.find(r => r.status === 'upcoming');
                const user = users[plan.userId] || {};
                return nextReturn ? {
                    'Username': user.username || 'N/A',
                    'Phone Number': user.phoneNumber || 'N/A',
                    'Plan ID': plan.planId,
                    'Month': nextReturn.month,
                    'Amount (₹)': nextReturn.amount,
                } : null;
            }).filter(Boolean);
        } else if (view === 'paid') {
            dataToDownload = plans.filter(plan => plan.status !== 'closed').flatMap(plan =>
                plan.returns
                    .filter(r => r.status === 'paid')
                    .map(returnItem => ({
                        'Username': users[plan.userId]?.username || 'N/A',
                        'Phone Number': users[plan.userId]?.phoneNumber || 'N/A',
                        'Plan ID': plan.planId,
                        'Month': returnItem.month,
                        'Amount (₹)': returnItem.amount,
                        'Date': new Date(returnItem.paidAt).toLocaleDateString(),
                    }))
            );
        } else if (view === 'reinvested') {
            dataToDownload = plans.filter(plan => plan.status !== 'closed').flatMap(plan =>
                plan.returns
                    .filter(r => r.status === 'reinvested')
                    .map(returnItem => ({
                        'Username': users[plan.userId]?.username || 'N/A',
                        'Phone Number': users[plan.userId]?.phoneNumber || 'N/A',
                        'Plan ID': plan.planId,
                        'Month': returnItem.month,
                        'Amount (₹)': returnItem.amount,
                        'Reinvested Date': new Date(returnItem.reinvestedAt).toLocaleDateString(),
                    }))
            );
        }

        const ws = XLSX.utils.json_to_sheet(dataToDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Monthly Returns');
        XLSX.writeFile(wb, `${view.charAt(0).toUpperCase() + view.slice(1)}Returns.xlsx`);
    };

    if (loading) return <Spin tip="Loading..." />;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    const filteredPlans = upcomingPlans.filter(plan => {
        const user = users[plan.userId];
        return (
            user &&
            (user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.phoneNumber.includes(searchTerm))
        );
    });

    const paidPlans = plans.flatMap(plan =>
        plan.returns
            .filter(r => r.status === 'paid' && plan.status !== 'closed')
            .map(returnItem => ({
                ...plan,
                ...returnItem,
                username: users[plan.userId]?.username || 'N/A',
                phoneNumber: users[plan.userId]?.phoneNumber || 'N/A'
            }))
    ).sort((a, b) => new Date(b.paidAt) - new Date(a.paidAt));

    const reinvestedPlans = plans.flatMap(plan =>
        plan.returns
            .filter(r => r.status === 'reinvested' && plan.status !== 'closed')
            .map(returnItem => ({
                ...plan,
                ...returnItem,
                username: users[plan.userId]?.username || 'N/A',
                phoneNumber: users[plan.userId]?.phoneNumber || 'N/A'
            }))
    ).sort((a, b) => new Date(b.reinvestedAt) - new Date(a.reinvestedAt));

    const handleView = (plan) => {
        const user = users[plan.userId];
        if (user) {
            setSelectedUser(user);
        }
    };

    const closeModal = () => {
        setSelectedUser(null);
    };

    const columns = {
        upcoming: [
            {
                title: 'S.No.',
                render: (text, record, index) => index + 1,
            },
            { title: 'Username', dataIndex: 'username' },
            { title: 'Phone Number', dataIndex: 'phoneNumber' },
            { title: 'Plan ID', dataIndex: 'planId' },
            {
                title: 'Month',
                render: (text, record) => {
                    const nextReturn = record.returns.find(r => r.status === 'upcoming');
                    return nextReturn ? nextReturn.month : 'N/A';
                },
            },
            {
                title: 'Amount (₹)',
                render: (text, record) => {
                    const nextReturn = record.returns.find(r => r.status === 'upcoming');
                    return nextReturn ? nextReturn.amount : 'N/A';
                },
            },
            {
                title: 'Pay Date',
                render: (text, record) => {
                    const nextReturn = record.returns.find(r => r.status === 'upcoming');
                    return nextReturn ? new Date(nextReturn.payDate).toLocaleDateString() : 'N/A';
                },
            },
            {
                title: 'Actions',
                render: (text, record) => {
                    const nextReturn = record.returns.find(r => r.status === 'upcoming');
                    const payDate = nextReturn ? new Date(nextReturn.payDate) : null;
                    const isPayable = payDate && new Date() >= payDate;
    
                    return (
                        <>
                            <Button onClick={() => handleView(record)}>View</Button>
                            <Button
                                onClick={() => handlePayReturn(record)}
                                disabled={!isPayable}
                                style={{ marginLeft: '10px' }}
                            >
                                Pay
                            </Button>
                        </>
                    );
                },
            },
        ],
        paid: [
            {
                title: 'S.No.',
                render: (text, record, index) => index + 1,
            },
            { title: 'Username', dataIndex: 'username' },
            { title: 'Phone Number', dataIndex: 'phoneNumber' },
            { title: 'Plan ID', dataIndex: 'planId' },
            { title: 'Month', dataIndex: 'month' },
            { title: 'Amount (₹)', dataIndex: 'amount' },
            { title: 'Date', render: (text, record) => new Date(record.paidAt).toLocaleDateString() },
        ],
        reinvested: [
            {
                title: 'S.No.',
                render: (text, record, index) => index + 1,
            },
            { title: 'Username', dataIndex: 'username' },
            { title: 'Phone Number', dataIndex: 'phoneNumber' },
            { title: 'Plan ID', dataIndex: 'planId' },
            { title: 'Month', dataIndex: 'month' },
            { title: 'Amount (₹)', dataIndex: 'amount' },
            { title: 'Reinvested Date', render: (text, record) => new Date(record.reinvestedAt).toLocaleDateString() },
        ],
    };

    return (
        <div className="monthly-returns-container">
            <div className="header">
                <Button type="primary" onClick={downloadData}>Download</Button>
                <Search
                    placeholder="Search by Username or Phone Number"
                    onSearch={setSearchTerm}
                    style={{ width: 300, marginLeft: 10 }}
                    allowClear
                />
                <div>
                    <Button onClick={() => toggleView('upcoming')} style={{ borderBottom: view === 'upcoming' ? '2px solid #007bff' : 'none' }}>Pending Returns</Button>
                    <Button onClick={() => toggleView('paid')} style={{ borderBottom: view === 'paid' ? '2px solid #007bff' : 'none', marginLeft: '5px' }}>Paid Returns</Button>
                    <Button onClick={() => toggleView('reinvested')} style={{ borderBottom: view === 'reinvested' ? '2px solid #007bff' : 'none', marginLeft: '5px' }}>Reinvested Returns</Button>
                </div>
            </div>

            <div className="table-container">
                <h5>{view.charAt(0).toUpperCase() + view.slice(1)} Returns</h5>
                <Table
                    columns={columns[view]}
                    dataSource={view === 'upcoming' ? filteredPlans : view === 'paid' ? paidPlans : reinvestedPlans}
                    rowKey="_id"
                    pagination={false}
                />
            </div>
            <Modal
    title="Pay Return"
    visible={isModalVisible}
    onCancel={() => setIsModalVisible(false)} // Close modal
    footer={[
        <Button key="cancel" onClick={() => setIsModalVisible(false)}>Cancel</Button>,
        <Button
            key="submit"
            type="primary"
            onClick={handleSubmitPayReturn} // Submit the payment
            loading={submitting}
            disabled={!transactionId} // Disable if no transaction ID
        >
            Submit
        </Button>
    ]}
>
    <div>
        <p><strong>Plan ID:</strong> {selectedPlan?.planId}</p>
        <p><strong>Month:</strong> {selectedPlan?.returns?.[0]?.month}</p>
        <p><strong>Amount (₹):</strong> {selectedPlan?.returns?.[0]?.amount}</p>
        <Input
            placeholder="Enter Transaction ID"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            style={{ marginBottom: 10 }}
        />
    </div>
</Modal>


            <Modal
                title="User Bank Details"
                visible={!!selectedUser}
                onCancel={closeModal}
                footer={[<Button key="close" onClick={closeModal}>Close</Button>]}
            >
                {selectedUser && (
                    <>
                        <p><strong>Username:</strong> {selectedUser.username}</p>
                        <p><strong>Phone Number:</strong> {selectedUser.phoneNumber}</p>
                        <h3>Bank Account Details</h3>
                        <p><strong>Bank Name:</strong> {selectedUser.bankaccount.bankName}</p>
                        <p><strong>Account Holder Name:</strong> {selectedUser.bankaccount.accountHolderName || 'N/A'}</p>
                        <p><strong>Account Number:</strong> {selectedUser.bankaccount.accountNumber || 'N/A'}</p>
                        <p><strong>IFSC Code:</strong> {selectedUser.bankaccount.ifscCode || 'N/A'}</p>
                    </>
                )}
                
            </Modal>
            <style jsx>{`
    .monthly-returns-container {
        padding: 20px;
        background-color: #f8f9fa;
        overflow-y: auto;
        max-height: 100vh;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .table-container {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
    }

    .ant-table-row:hover {
        background-color: transparent !important; /* Keeps background transparent */
    }

    .ant-table-row:hover td {
        background-color: transparent !important; /* Prevents cells from changing background */
        color: inherit !important; /* Maintains original text color */
    }

    .ant-table td {
        transition: background-color 0.3s ease; /* Smooth transition */
    }
`}</style>

        </div>
    );
};

export default MonthlyReturns;
